import React from 'react'
import { graphql, Link } from 'gatsby'
import styled, { ThemeProvider } from 'styled-components/macro'

// Components
import SeoComponent from '@components/SeoComponent/SeoComponent'
import FullImageHeroComponent from '@components/FullImageHeroComponent'
import AuthorComponent from '@components/AuthorComponent'
import Spacer from '@components/SpacerComponent'
import { ContentComponent } from '@components/MdxComponents'
import SocialShareIconsComponent from '@components/SocialShareIconsComponent'
import Grid from '@components/ViewGridComponent'
import { Paragraph } from '@components/Typography'
import SectionComponent from '@components/SectionComponent'

// Assets
// @ts-expect-error
import { lemonListIcon } from '@assets/'

// Hooks
import useIsTablet from '@hooks/useIsTablet'
import { Caption } from '@components/Typography'

type PillarProps = {
  data: {
    mdx: Page
  }
  path: string
  uri: string
  params: object
  pageResources: any
  navigate: any
  pageContext: {
    id: string
    frontmatter: {
      slug: string
    }
  }
}

type Page = {
  body: string
  timeToRead: number
  frontmatter: {
    slug: string
    heroTitle: string
    heroDescription: string
    heroImage: string
    authorTitle: string
    authorProfile: string
    author: string
    metaTitle: string
    metaDescription: string
    metaImage: string
  }
  tableOfContents: {
    items: Array<{
      url: string
      title: string
    }>
  }
}

type HeroBannerProps = {}

type TableOfContentsProps = Page['tableOfContents'] & {
  slug: string
}

const TableOfContents: React.FC<TableOfContentsProps> = props => {
  const isTablet = useIsTablet()

  return (
    <Spacer top={isTablet ? 'base' : 'lg'} bottom={isTablet ? '0' : 'xl'}>
      <Caption>Table of Contents</Caption>
      <Line />
      <ul>
        {props.items.map(item => {
          return (
            <ListItem>
              {/* Done this way to have a smoother scroll animation as Gatsby was "changing" the path to add the anchor link  */}
              <TableItem to={`${props.slug}${item.url}`}>
                <Icon src={lemonListIcon} alt='lemon icon' />
                <Paragraph size='sm'>{item.title}</Paragraph>
              </TableItem>
            </ListItem>
          )
        })}
      </ul>
      <Line />
      <SocialShareIconsComponent />
    </Spacer>
  )
}

const HeroBanner: React.FC<HeroBannerProps> = () => {
  return null
}

const PillarTemplate: React.FC<PillarProps> = props => {
  const page = props.data.mdx
  const pageMeta = page.frontmatter

  return (
    <>
      <Article>
        <SeoComponent
          title={pageMeta.metaTitle}
          description={pageMeta.metaDescription}
          image={pageMeta.metaImage}
        />
        <FullImageHeroComponent
          image={{ src: pageMeta.heroImage, alt: pageMeta.heroTitle }}
          reduceHeroHeight={false}
          heading={{
            title: pageMeta.heroTitle,
            copy: pageMeta.heroDescription,
            readingTime: page.timeToRead,
          }}
          content={
            <TableOfContents
              items={page.tableOfContents.items}
              slug={pageMeta.slug}
            />
          }
          removePadding
        />
        <ContentComponent body={page.body} />
        <Spacer bottom='xl2' />
      </Article>
    </>
  )
}

export default PillarTemplate

const Article = styled.article`
  padding-top: ${({ theme }) => theme.spacing.xl2};
`

const Line = styled.div`
  width: 100%;
  border-bottom: solid 1px var(--color-text);
  opacity: 0.5;
  margin: ${({ theme }) => theme.spacing.sm} 0;
`

const Icon = styled.img`
  margin-right: ${props => props.theme.spacing.xs};
`

const TableItem = styled(Link)`
  display: flex;
  margin-bottom: ${props => props.theme.spacing.xs};
  text-decoration: none;
  &:hover {
    color: var(--color-accent) important!;
  }
`

const ListItem = styled.li``

export const pageQuery = graphql`
  query Pillar($id: String!) {
    mdx(id: { eq: $id }) {
      slug
      frontmatter {
        slug
        author
        authorProfile
        authorTitle
        heroDescription
        heroImage
        heroTitle
        metaDescription
        metaImage
        metaTitle
        title
      }
      body
      timeToRead
      tableOfContents(maxDepth: 2)
    }
  }
`
