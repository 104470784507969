import React, { useEffect } from 'react'
import SocialIconList from '@components/SocialIconListComponent'

const SocialShareIconsComponent: React.FC<{
  removeMargin?: boolean
}> = props => {
  const [url, setUrl] = React.useState<string>('')

  useEffect(() => {
    setUrl(window.location.href)
  }, [])

  return (
    <SocialIconList
      removeMargin={props.removeMargin}
      icons={[
        {
          href: `https://twitter.com/intent/tweet?url=${url}&text=`,
          ariaLabel: 'Go to our Twitter',
          iconType: 'twitter',
        },
        {
          href: `https://www.linkedin.com/shareArticle?mini=true&url=${url}`,
          ariaLabel: 'Go to our LinkedIn',
          iconType: 'linkedin',
        },
        {
          href: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
          ariaLabel: 'Go to our Facebook',
          iconType: 'facebook',
        },
      ]}
    />
  )
}

export default SocialShareIconsComponent
